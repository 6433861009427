interface AnimalCam {
  name: string;
  url: string;
}

const allCams: AnimalCam[] = [
  {
    name: "Bat World Sanctuary",
    url: "https://batworld.org/bat-cams/",
  },
  {
    name: "San Diego Zoo Platypus Cam",
    url: "https://www.sdzsafaripark.org/platypus-cam",
  },
  {
    name: "San Diego Zoo Penguin Cam",
    url: "https://zoo.sandiegozoo.org/cams/penguin-cam",
  },
  {
    name: "San Diego Zoo Burrowing Owl Cams",
    url: "https://www.sdzsafaripark.org/cams/burrowing-owl-cams",
  },
  {
    name: "San Diego Zoo Condor Cam",
    url: "https://www.sdzsafaripark.org/condor-cam",
  },
  {
    name: "Wolf Conservation Center",
    url: "https://www.twitch.tv/wolfconservationcenter",
  },
  {
    name: "DashDucks",
    url: "https://www.twitch.tv/dashducks",
  },
  {
    name: "Hoot House",
    url: "https://www.twitch.tv/hoothouselivestream",
  },
  {
    name: "Our Chicken Life",
    url: "https://www.twitch.tv/ourchickenlife",
  },
  {
    name: "Golden Gate Ospreys",
    url: "http://sfbayospreys.org",
  },
  {
    name: "Marine Mammal Rescue (Otters)",
    url: "https://www.twitch.tv/marinemammalrescue",
  },
  {
    name: "Parrot Town TV: Bird Room Buddies",
    url: "https://www.youtube.com/watch?v=m_ZW1mxGh0s",
  },
];

let animalCams = allCams.slice();

// Fisher-Yates shuffle
function shuffle<T>(arr: T[]): void {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor((i + 1) * Math.random());
    const tmp = arr[j];
    arr[j] = arr[i];
    arr[i] = tmp;
  }
}

function reset(): void {
  animalCams = allCams.slice();
  shuffle(animalCams);
}

function pop(): AnimalCam {
  if (animalCams.length < 1) {
    reset();
  }
  return animalCams.shift();
}

export function next(): void {
  clearTimeout(ticker);

  const cam = pop();

  const template: HTMLTemplateElement = document.querySelector("#redirect");
  const content = template.content.cloneNode(true) as HTMLParagraphElement;
  const a = anchor(cam);
  a.id = "cam";
  content.querySelector("#cam").replaceWith(a);

  const dest = document.querySelector("#dynamic");
  dest.replaceChildren(content);
  ticker = setTimeout(tick, 1000);
}

let ticker: number;

function tick(): void {
  const content = document.querySelector("#dynamic");
  const cam = content.querySelector("#cam") as HTMLAnchorElement;
  const delay = content.querySelector("#delay") as HTMLSpanElement;

  const seconds = parseInt(delay.textContent) - 1;
  delay.textContent = seconds.toString();
  if (seconds < 1) {
    window.location.assign(cam.href);
    return;
  }
  ticker = setTimeout(tick, 1000);
}

export function list(): void {
  clearTimeout(ticker);
  const dest = document.querySelector("#dynamic");

  const ul = document.createElement("ul");
  for (const cam of allCams) {
    const li = document.createElement("li");
    li.appendChild(anchor(cam));
    ul.append(li);
  }

  dest.replaceChildren(ul);
}

function anchor(cam: AnimalCam): HTMLAnchorElement {
  const a = document.createElement("a");
  a.href = cam.url;
  a.textContent = cam.name;
  return a;
}
